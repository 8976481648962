.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
#root{
  background-color:#fff !important;
  min-height: 100vh;
  
}
.mrp{
  color: red;
  text-decoration: line-through;
  font-size: 14px;
}
.img-fluid {
  max-height: 15  0px;
}
.width-100{
  width: 100%;
}
.react-pdf__Page__textContent.textLayer{
    display: none;
}


@font-face { font-family: "NHaasGroteskDSPro-65Md" !important;
	src:url('./assets/fonts/boost-next-black.eot'); 
	src:url('./assets/fonts/boost-next-black.eot?#iefix') format('embedded-opentype'),
		url('./assets/fonts/boost-next-black.woff') format('woff'),
		url('./assets/fonts/boost-next-black.ttf') format('truetype'),
		url('./assets/fonts/boost-next-black.svg#boost-next-black') format('svg');
	font-weight: normal; font-style: normal;
}
@font-face { font-family:'boost-next-heavy';
	src:url('./assets/fonts/boost-next-heavy.eot'); 
	src:url('./assets/fonts/boost-next-heavy.eot?#iefix') format('embedded-opentype'),
		url('./assets/fonts/boost-next-heavy.woff') format('woff'),
		url('./assets/fonts/boost-next-heavy.ttf') format('truetype'),
		url('./assets/fonts/boost-next-heavy.svg#boost-next-heavy') format('svg');
	font-weight: normal; font-style: normal;
}

@font-face { font-family:'boost-next-light';
	src:url('./assets/fonts/boost-next-light.eot'); 
	src:url('./assets/fonts/boost-next-light.eot?#iefix') format('embedded-opentype'),
		url('./assets/fonts/boost-next-light.woff') format('woff'),
		url('./assets/fonts/boost-next-light.ttf') format('truetype'),
		url('./assets/fonts/boost-next-light.svg#boost-next-light') format('svg');
	font-weight: normal; font-style: normal;
}
@font-face { font-family:'boost-next-thin';
	src:url('./assets/fonts/boost-next-thin.eot'); 
	src:url('./assets/fonts/boost-next-thin.eot?#iefix') format('embedded-opentype'),
		url('./assets/fonts/boost-next-thin.woff') format('woff'),
		url('./assets/fonts/boost-next-thin.ttf') format('truetype'),
		url('./assets/fonts/boost-next-thin.svg#boost-next-thin') format('svg');
	font-weight: normal; font-style: normal;
}
@font-face { font-family: "NHaasGroteskDSPro-65Md";
	src:url('./assets/fonts/boost-next-bold.eot'); 
	src:url('./assets/fonts/boost-next-bold.eot?#iefix') format('embedded-opentype'),
		url('./assets/fonts/boost-next-bold.woff') format('woff'),
		url('./assets/fonts/boost-next-bold.ttf') format('truetype'),
		url('./assets/fonts/boost-next-bold.svg#boost-next-bold') format('svg');
	font-weight: normal; font-style: normal;
}
@font-face { font-family:'boost-next-regular';
	src:url('./assets/fonts/boost-next-regular.eot'); 
	src:url('./assets/fonts/boost-next-regular.eot?#iefix') format('embedded-opentype'),
		url('./assets/fonts/boost-next-regular.woff') format('woff'),
		url('./assets/fonts/boost-next-regular.ttf') format('truetype'),
		url('./assets/fonts/boost-next-regular.svg#boost-next-regular') format('svg');
	font-weight: normal; font-style: normal;
}
.signup{
	padding: 50px 0px;
}
.logo-img{
	margin-bottom: 70px;
}
.logo-img img {
	max-width: 180px;
}
.signup .container{
	max-width: 430px !important;
}
.signup .form-control{
	background: #FFFFFF;
	border: 1px solid #3B3B3C !important;
	border-radius: 45px;
	opacity: 1;
	text-align: center;
    padding: 10px;
    height: 50px;
    font-size: 20px !important;
    color: #3B3B3C !important;
	font-family: 'NHaasGroteskDSPro-55Rg' !important;
}
.signup .form-group{
	margin-bottom: 25px !important;
}
.signup h3{
	font-size: 28px;
	color: #3B3B3C !important;
	font-family: "NHaasGroteskDSPro-65Md" !important;
	margin-bottom: 30px;
}
/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    font-size: 20px !important;
    color: #3B3B3C !important;
	font-family: 'NHaasGroteskDSPro-55Rg' !important;
}
.btn-primary, .btn-primary:focus {
	
	background: #fc5400 !important;
	border-color: #fc5400 !important;
	font-size: 20px !important;
	color: #fff;
	text-align: center;
	border-radius: 19px !important;
	min-width: 130px;
	font-family: "NHaasGroteskDSPro-65Md";
	padding: 4px 15px !important;
	text-transform: uppercase;
	box-shadow: none !important;
}
.Back-btn,.Back-btn:hover,.Back-btn:focus{
	font-size: 18px;
	text-decoration: none;
	color: #3B3B3C;
	font-family: "NHaasGroteskDSPro-65Md";
	text-transform: uppercase;
}
.Back-btn img{
	max-width: 13px;
    vertical-align: text-top;
    margin-right: 7px;
}
.btn-outline-primary{
	background: #fff !important;
	border-color: #fc5400 !important;
	font-size: 20px !important;
	color: #fc5400 !important;
	text-align: center;
	border-radius: 19px !important;
	min-width: 130px;
	font-family: "NHaasGroteskDSPro-65Md";
	padding: 4px 15px !important;
	text-transform: uppercase;
}
.btn-outline-primary:hover{
	background: #fc5400 !important;
	color: #ffffff !important;
}
.btn-secondary{
	background: #003960 !important;
	border-color: #003960 !important;
	font-size: 20px !important;
	color: #fff;
	text-align: center;
	border-radius: 19px !important;
	min-width: 130px;
	font-family: "NHaasGroteskDSPro-65Md";
	padding: 4px 15px !important;
	text-transform: uppercase;
}
.btn-secondary:active, .btn-secondary:focus {
	background: #012842 !important;
	border-color: #012842 !important;
	color: #fff;
}
.btn-default{
	background: #71CBE4 !important;
	border-color: #71CBE4 !important;
	font-size: 20px !important;
	color: #fff !important;
	text-align: center;
	border-radius: 19px !important;
	min-width: 130px;
	font-family: "NHaasGroteskDSPro-65Md";
	padding: 4px 15px !important;
	text-transform: uppercase !important;
}
.signup .btn-primary{
	border-radius: 45px !important;
	height: 50px;
	margin-bottom: 20px;
	background: #fc5400 !important;
	border-color: #fc5400 !important;
	font-size: 20px !important; 
	color: #fff;
	text-align: center;
}
.full-width{
	width: 100%;
}
.signup-footer-link a,.signup-footer-link a:hover,.signup-footer-link a:focus{
	font-size: 15px;
	color:#1492E6 ;
	font-family: "NHaasGroteskDSPro-65Md";
}
.signup{
	min-height: calc(100vh - 40px);
}
.footer-section{
	background: #404040;
	text-align: center;
	font-size: 16px;
	color: #71CBE4;
	padding: 8px 10px;
	font-family: "NHaasGroteskDSPro-65Md";
}

/*modal css*/
.modal-dialog:not(.modal-sm){
	max-width: 600px !important;
}
.modal-content{
	border-radius: 10px !important;
}
.modal-header{
	padding-top: 20px !important;
	padding-left: 30px !important;
	padding-right: 30px !important;
	flex-wrap: wrap;
	border-bottom: none !important;
}
.modal-header p{
	margin-bottom: 0px;
	font-size: 20px;
	color: #3B3B3C;
	font-family: "NHaasGroteskDSPro-65Md";
	line-height: 24px;
}
.modal-body{
	padding-top: 0px !important;
	padding-left: 30px !important;
	padding-right: 30px !important;
}
.modal-title {
	font-family: "NHaasGroteskDSPro-65Md" !important;
	font-size: 28px!important ;
	color: #707070;
	margin-bottom: 10px !important
}
.modal-header .close{
	background: url(./assets/img/modal-close-icon.svg) no-repeat center center;
	font-size: 0px !important;
    position: absolute;
    top: 12px !important;
    right: 12px !important;
    margin: 0px !important;
    opacity: 1 !important;
}
.Modal-form-label,.form-label{
	font-size: 20px;
	color: #525F7F;
	font-family: "NHaasGroteskDSPro-65Md";
	line-height: 24px;
	font-weight: normal;
}
.modal-footer{
	padding-left: 30px !important;
	padding-right: 30px !important;
	padding-bottom: 30px !important;
	flex-wrap: wrap;
	border-top: none !important;
}
.form-control{
	font-size: 20px !important;
	color: #3B3B3C !important;
	border: 1px solid #DEE2E6 !important;
	font-family: "NHaasGroteskDSPro-65Md";
	height: 46px !important;
	border-color: #DEE2E6 !important;
}
.logo-img.mb-30 {
    margin-bottom: 30px;
}
.custom-navbar{
	background: #fc5400;
	padding:35px 20px !important;
}
.header-right .dropdown-toggle,.header-right .dropdown-toggle:hover{
	font-size: 18px !important;
	text-transform: capitalize !important;
	font-family: 'NHaasGroteskDSPro-55Rg' !important;
	outline: none !important;
	box-shadow: none !important;
}
.header-right .dropdown-toggle::after{
	border: none !important;
	width: 18px;
	height: 10px;
	vertical-align: middle;
	margin-left: 10px !important;
	background: url(./assets/img/chevron-down-solid.svg) no-repeat;
	background-size: 18px;
}
.header-right .dropdown-item{
	padding: 0px ;
}
.full_text {
	white-space: nowrap;
}
.page-inner-cover{
	min-height: calc(100vh - 160px);
	/*padding-top: 20px;*/
}
.category-top{
	padding: 20px 0px;
}
.category-bottom .table thead th{
	border-top: 1px solid #003960;
	border-bottom: 1px solid #003960;
	color: #707070 ;
	white-space: nowrap;
	font-size: 20px;
	font-weight: normal;
	font-family: "NHaasGroteskDSPro-65Md" !important;
}
.category-top .btn-primary, .category-top .btn-primary:active, .category-top .btn-primary:focus {
	    background: #fff !important;
    color: #3B3B3C !important;
    font-size: 18px !important;
    font-family: "NHaasGroteskDSPro-65Md";
    border-color: #3B3B3C !important;
    padding: 6px 32px !important;
    text-transform: uppercase;
    line-height: 28px;
    border-radius: 50px !important;
}
.btn-outline-secondary,.btn-outline-secondary:hover,.btn-outline-secondary:active {
	background: #fff !important;
    color: #3B3B3C !important;
    font-size: 18px !important;
    font-family: "NHaasGroteskDSPro-65Md";
    border-color: #3B3B3C !important;
    padding: 6px 32px !important;
    text-transform: uppercase;
    line-height: 28px !important;
    border-radius: 50px !important;
}
.category-top-flyer .btn-primary:first-child:active ,.category-top-flyer .btn-primary:first-child:focus {
	background: #fff !important;
    color: #3B3B3C !important;
	border-color: #3B3B3C !important;
}
.category-title{
	font-size: 40px  !important;
	color: #3B3B3C !important;
	font-family: "NHaasGroteskDSPro-65Md" !important;
	padding-left: 20px !important; 
	line-height: 40px;
}
.category-bottom .table{
	border-collapse: inherit; 
    border-spacing: 0px 8px;
}
.category-bottom .table tbody td{
	vertical-align: middle;
	border-top: none !important;
	

}
.category-bottom .table tbody td:first-child{
	padding-left: 20px;
}
.category-bottom .table tbody td,.category-bottom .table tbody td a{
	font-size: 20px;
	color: #3B3B3C;
	text-transform: capitalize;
}
.custom-switch{
	width: 100px;
	    overflow: hidden;
    height: 41px;
}
.custom-switch-text{
	position: absolute;
    width: 31px;
    height: 31px;
    border-radius: 27px;
    /* background-color: white; */
    top: 5px;
    left: -30px;
    box-shadow: 0px 0px 1px rgb(150 150 150);
    /*transition: all .3s;*/
}

.custom-switch-text:before,
.custom-switch-text:after {
        position: absolute;
    content: 'ON';
    width: calc(78px - 40px);
    height: 37px;
    line-height: 37px;
    font-family: "NHaasGroteskDSPro-65Md";
    font-size: 20px;
    font-weight: bold;
    top: -1px;
}
.custom-control-input:checked~ .custom-control-label .custom-switch-text {
    left: calc(60px - 32px);
    box-shadow: 0px 0px 0px white;
}
.custom-switch-text:before {
    content: 'OFF';
    color: #707070;
    left: 100%;
    margin-left: 8px;
    opacity: 1;

}
.custom-switch-text:after {
    content: 'ON';
    right: 100%;
    color: #fc5400;
}
.custom-switch .custom-control-label::before{
	width: 100px  !important;
	border-color: #707070 !important;
	height: 41px;
	top: 0px;
	border-radius: 30px !important;
}
.custom-switch .custom-control-label::after{
	background: #707070 !important;
	width: 30px !important;
    height: 30px !important;
    top: 6px !important;
    left: -29px !important;
    border-radius: 30px !important;
}

.custom-control-input:checked~.custom-control-label::before{
	background-color: #fff !important;
	border-color: #fc5400 !important;
}
.custom-switch .custom-control-input:checked~.custom-control-label::after{
	background: #fc5400 !important;
	transform: translateX(56px) !important;
}
.pdf-modal-dragbox{
	height: 320px;
	width: 100%;
	position: relative;
	padding: 20px;
	overflow: hidden;
	border: 2px dashed #70707080;
	display: flex;
	align-items: flex-start;
	justify-content: center;
}
.react-datepicker-popper{
  z-index: 9999999 !important;
  padding-bottom: 10px;
}
.pdf-modal-dragbox-file{
	height: 320px;
	width: 100%;
}
.pdf-modal-dragbox .row,.pdf-modal-dragbox .col,.pdf-modal-dragbox .react-pdf__Document{
	align-items: flex-start;
	height: 100%;
}
.pdf-modal-dragbox .react-pdf__message.react-pdf__message--no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.form-control-file{
	position: absolute;
	opacity: 0 !important;
	left: 0px;
	height: 320px;
	width: 100%;
	z-index: 999;
}
.pdf-drag-drop-text{
	font-size: 20px;
	text-align: center;
	color: #525F7F;
	line-height: 24px;
	font-family: "NHaasGroteskDSPro-65Md" !important;
}
.pdf-drag-drop-text span{
	color: #fc5400;
}
.pdf-drag-drop-icon{
	margin-bottom: 20px;
}
.pdf-modal-dragbox.active {
    border-color: red;
}
.modal-dialog .form-control{
	font-family: 'NHaasGroteskDSPro-55Rg' !important;
	color: #525F7F !important;
}
.pdf-close {
    position: absolute;
    top: 15px;
    right: 20px;
    cursor: pointer;
    z-index: 9999;
}
.custom-control-input:focus ~ .custom-control-label::before {
	box-shadow: none !important;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range{
	/*background: #fc5400  !important;*/
}
.delete-modal .modal-dialog{
	max-width: 400px !important;
	text-align: center;
}
.delete-modal .modal-dialog .modal-title{
    width: 100%;
    text-align: center;
}
.delete-modal .modal-dialog p{
	font-size: 16px;
	color: #707070;
	font-family: "NHaasGroteskDSPro-65Md";
}
.delete-modal .modal-footer{
	justify-content: center;
}
/* .dropdown-item .nav-link{
	font-size: 22px;
	color: #FFFFFF;
	font-family:'boost-next-regular';
	text-align: center;
	font-weight: normal;
    padding: 5px 10px;
	line-height: 20px;
} */
.dropdown-item:hover,.dropdown-item:focus{
	background: none !important;
}
/* .header-right .dropdown-menu{
	background: none;
	border: none;
	min-width: 100%;
	padding: 0px;
} */
@media (min-width: 1200px){
	.container, .container-lg, .container-md, .container-sm, .container-xl {
	    max-width: 90% !important;
	}
}

@media (max-width: 767px){
	.container, .container-sm {
	    max-width: 100% !important;
	}
	.modal-title{
		    font-size: 22px!important;
	}
	.Modal-form-label, .form-label{
		font-size: 18px;
	}
	.form-control,.btn-primary,.btn-secondary {
    		font-size: 18px !important;
	}
	.category-title{
		    font-size: 25px !important;
		    line-height: 30px;
	}
	.category-bottom .table tbody td, .category-bottom .table tbody td a {
    		font-size: 18px;
	}
	.category-bottom .table thead th{
		    white-space: nowrap;
		    font-size: 16px;
	}
}
@media (max-width: 479px){
	.custom-navbar .container{
		    justify-content: center !important;
	}
	.navbar-brand{
		margin-right: 0px !important;
	}
	.category-top .btn-primary{
		    padding: 6px 8px !important;
		        font-size: 16px !important;
	}

}
.navbar-brand img{
	max-width: 100%;
}


.lds-roller {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
  }
  .lds-roller div {
	animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	transform-origin: 40px 40px;
  }
  .lds-roller div:after {
	content: " ";
	display: block;
	position: absolute;
	width: 7px;
	height: 7px;
	border-radius: 50%;
	background: #fff;
	margin: -4px 0 0 -4px;
  }
  .lds-roller div:nth-child(1) {
	animation-delay: -0.036s;
  }
  .lds-roller div:nth-child(1):after {
	top: 63px;
	left: 63px;
  }
  .lds-roller div:nth-child(2) {
	animation-delay: -0.072s;
  }
  .lds-roller div:nth-child(2):after {
	top: 68px;
	left: 56px;
  }
  .lds-roller div:nth-child(3) {
	animation-delay: -0.108s;
  }
  .lds-roller div:nth-child(3):after {
	top: 71px;
	left: 48px;
  }
  .lds-roller div:nth-child(4) {
	animation-delay: -0.144s;
  }
  .lds-roller div:nth-child(4):after {
	top: 72px;
	left: 40px;
  }
  .lds-roller div:nth-child(5) {
	animation-delay: -0.18s;
  }
  .lds-roller div:nth-child(5):after {
	top: 71px;
	left: 32px;
  }
  .lds-roller div:nth-child(6) {
	animation-delay: -0.216s;
  }
  .lds-roller div:nth-child(6):after {
	top: 68px;
	left: 24px;
  }
  .lds-roller div:nth-child(7) {
	animation-delay: -0.252s;
  }
  .lds-roller div:nth-child(7):after {
	top: 63px;
	left: 17px;
  }
  .lds-roller div:nth-child(8) {
	animation-delay: -0.288s;
  }
  .lds-roller div:nth-child(8):after {
	top: 56px;
	left: 12px;
  }
  @keyframes lds-roller {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
  }
.loaderCoder{
	position: fixed;
	left: 0;
	top: 0;
    height: 100%;
	width: 100%;
	background-color: rgba(0,0,0,0.5);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 99999;
}

.loaderCoderx{
	position: relative;
	left: 0;
	top: 0;
    height: 100%;
	width: 100%;
	background-color: rgba(0,0,0,0.5);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 99999;
}