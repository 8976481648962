/* naveen work */
@font-face {
    font-family: 'DrukWideHeavyTrial';
    src: url('../fonts/DrukWideHeavyTrial.eot');
    src: url('../fonts/DrukWideHeavyTrial.eot') format('embedded-opentype'),
         url('../fonts/DrukWideHeavyTrial.woff2') format('woff2'),
         url('../fonts/DrukWideHeavyTrial.woff') format('woff'),
         url('../fonts/DrukWideHeavyTrial.ttf') format('truetype'),
         url('../fonts/DrukWideHeavyTrial.svg#DrukWideHeavyTrial') format('svg');
}
@font-face{
    font-family: 'NHaasGroteskDSPro-55Rg';
    src: url('../fonts/NHaasGroteskDSPro-55Rg.eot');
    src: url('../fonts/NHaasGroteskDSPro-55Rg.eot?#iefix')format('embedded-opentype'),
        url('../fonts/NHaasGroteskDSPro-55Rg.woff')format('woff'),
        url('../fonts/NHaasGroteskDSPro-55Rg.ttf')format('truetype'),
        url('../fonts/NHaasGroteskDSPro-55Rg.svg#NHaasGroteskDSPro-55Rg')format('svg');
    font-weight:normal;
    font-style:normal;
    font-display:swap;
}
@font-face{
    font-family: "NHaasGroteskDSPro-65Md";
    src: url("../fonts/NHaasGroteskDSPro-65Md.eot");
    src: url("../fonts/NHaasGroteskDSPro-65Md.eot?#iefix")format("embedded-opentype"),
        url("../fonts/NHaasGroteskDSPro-65Md.woff")format("woff"),
        url("../fonts/NHaasGroteskDSPro-65Md.woff2")format("woff2"),
        url("../fonts/NHaasGroteskDSPro-65Md.ttf")format("truetype"),
        url("../fonts/NHaasGroteskDSPro-65Md.svg#NHaasGroteskDSPro-65Md")format("svg");
    font-weight:normal;
    font-style:normal;
    font-display:swap;
}
body{
	/*font-family: 'boost-next-regular' !important;*/
	font-family: 'NHaasGroteskDSPro-55Rg' !important;
}
.navbar-dark .navbar-brand{
	color: #fff;
    margin: 0;
    padding: 0;
}
.head-logo {
	margin-top: 20px !important;
	margin: 0 auto;
	text-align: center;
	margin-bottom: 10px;
}

.heade-title {
	margin-bottom: 20px;
	font-size: 25px;
	font-family: "NHaasGroteskDSPro-65Md" !important;
	text-align: center;
	font-family: "NHaasGroteskDSPro-65Md";
}

.head-logo img {
	max-width: 106px;
}
.head-logo-for-user img {
	max-width: 100%;
	margin-bottom: 20px;
}

.white-outer-box {
	padding: 30px 45px;
	box-shadow: 0px 9px 34px #00000017;
	border-radius: 10px;
	max-width: 450px;
	margin: 0 auto;

}

.signin-title {
	font-size: 25px;
	font-family: "NHaasGroteskDSPro-65Md" !important;
	margin-bottom: 30px;
	text-align: center;
	color: #FF7A03;
	
}

.input-sign-in-inner input {
	border-radius: 10px;
}

.reset-text a {
	font-size: 15px;
	color: #1492E6;
	margin-bottom: 15px;
	font-family: "NHaasGroteskDSPro-65Md";
}

.new-login-signup .btn-primary {
	border-radius: 10px !important;
	height: 50px;
	margin-bottom: 20px;
	background: #fc5400 !important;
	border-color: #fc5400 !important;
	font-size: 20px !important;
	color: #fff;
	text-align: center;
	border: none;
	font-family: "NHaasGroteskDSPro-65Md";
}

.dont-account {
	font-size: 15px;
	color: #727272;
	font-weight: normal;
	margin-bottom: 5px;
}

.auto-sign-up {
	margin: 0 auto;
	text-align: center;
}

.input-sign-in-inner input::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */

	opacity: 1;
	/* Firefox */
	font-size: 16px;
	color: #707070;
}

.input-sign-in-inner input:-ms-input-placeholder {
	/* Internet Explorer 10-11 */

	font-size: 16px;
	color: #707070;
}

.input-sign-in-inner input::-ms-input-placeholder {
	/* Microsoft Edge */

	font-size: 16px;
	color: #707070;
}



.header-outer {
	padding: 16px;
	box-shadow: 0px 3px 21px #00000029;
}

.search-inner .input-search {
	height: 50px;
	border-radius: 10px;
	border: 1px solid #DEDEDE;
	min-width: 360px;
	margin-right: 23px;
	padding-left: 40px;
	padding-right: 40px;
}

.input-search:focus {
	outline: none;
}

.title-categories {
	font-size: 30px;
	color: #3B3B3C;
	font-family: "NHaasGroteskDSPro-65Md" !important;
	margin-top: 25px;
	margin-bottom: 25px;
}
.title-categories.admin-title{
	font-size: 30px;
	color: #3B3B3C;
	font-family: "NHaasGroteskDSPro-65Md" !important;
	margin-top: 25px;
	margin-bottom: 25px;
	border-top: 1px solid #000;
			border-bottom: 1px solid #003960;
}

.client-name {
	font-size: 18px;
	color: #000000;
	font-family: "NHaasGroteskDSPro-65Md" !important;
}

.pdf-repeat-box {
	font-size: 20px;
	color: #3B3B3C;
	padding: 13px ;
	border-radius: 5px;
	border: 2px solid #fc5400;
	margin-bottom: 10px;
}

.center-title {
	font-size: 30px;
	color: #3B3B3C;
	font-family: "NHaasGroteskDSPro-65Md" !important;
}

.pre-btn {
	padding: 16px 20px;
	background-color: #fc5400;
	font-size: 16px;
	color: #FFFFFF;
	font-family: "NHaasGroteskDSPro-65Md" !important;
	border: none;
	border-radius: 10px;
}

.next-btn {
	padding: 16px 20px;
	background-color: #fc5400;
	font-size: 16px;
	color: #FFFFFF;
	font-family: "NHaasGroteskDSPro-65Md" !important;
	border: none;
	border-radius: 10px;
}

.next-btn span {
	margin-left: 10px;
}

.pre-btn span {
	margin-right: 10px;
}

.pdf-main-outer {
	margin-top: 20px;
	
}

.pdf-btn-outer {
	padding: 20px 0px;
	position: relative;
	text-align: center;
}

.pdf-btn-outer .react-pdf__Document {
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 20px;
}

.pdf-btn-outer #zoomInButton {
	font-size: 16px;
	color: #fff !important;
	font-family: "NHaasGroteskDSPro-65Md" !important;
	background: transparent;
	border: none;
}

.pdf-btn-outer #zoomOutButton {
	font-size: 16px;
	color: #fff !important;
	font-family: "NHaasGroteskDSPro-65Md" !important;
	background: transparent;
	border: none;
}

.pages-count {
	font-size: 16px;
	color: #fff;
}
.orange-btn{
	padding: 14px 18px;
	background: #fc5400;
	color: #fff;
	font-size: 16px;
	font-family: "NHaasGroteskDSPro-65Md" !important;
	border-radius: 10px;
	border: 1px solid #fff;
	min-width: 109px;
	height: 50px;
	margin-right: 30px;
}
.dropdown-cover button,.dropdown-cover button:focus{
	background: transparent !important;
	color: #000 !important;
	font-family: "NHaasGroteskDSPro-65Md" !important;
	outline: 0 !important;
	font-size: 18px !important;
	border: none !important;
	padding-left: 0 !important;
    padding-right: 0 !important;
	
    min-width: auto;
}
.dropdown-cover button:focus{
	box-shadow: none !important;
}
.dropdown-cover .dropdown-item .nav-link {
    font-size: 20px;
    color: #000000;
    font-family: 'NHaasGroteskDSPro-55Rg' !important;
    text-align: center;
    font-weight: normal;
    padding: 5px 10px;
    line-height: 20px;
}
.load-btn button{
	color: #3B3B3C;
	font-size: 20px;
}
.load-btn button:focus{
	box-shadow: none !important;
}
.dropdown-cover .dropdown-toggle::after{
	border: none;
	background: url(../img/drop.svg) no-repeat;
	background-position: center center;
    width: 15px;
    height: 15px;
	vertical-align: middle;
}
/* end */
	.input-sign-in-inner input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
		
		opacity: 1; /* Firefox */
		font-size: 16px;
		color: #707070;
	  }
	  
	  .input-sign-in-inner input:-ms-input-placeholder { /* Internet Explorer 10-11 */
		
		font-size: 16px;
		color: #707070;
	  }
	  
	  .input-sign-in-inner input::-ms-input-placeholder { /* Microsoft Edge */
		
		font-size: 16px;
		color: #707070;
	  }

	  
	  .header-outer{
		padding: 16px;
		box-shadow: 0px 3px 21px #00000029;
	  }
       .search-inner .input-search{
			height: 50px  ;
			border-radius: 10px;
			border: 1px solid #DEDEDE;
			min-width: 360px;
			margin-right: 10px;
			padding-left: 40px;
			padding-right: 20px;
			background: url(../img/magnifying-glass-solid.svg) no-repeat;
			background-position: 5%;
	   }

	   .user-front-nav-bar .search-inner .input-search{
			background: url(../img/magnifying-glass-solid-white.svg) no-repeat !important;
			background-position: 10px !important;
			padding-left: 32px !important;
			color: #fff;
	   }
	   .user-front-nav-bar{
			/* background: #fc5400; */
			padding:35px 20px !important;
		}
		.user-front-nav-bar .orange-btn{
			background: transparent;
		}
	   .user-front-nav-bar .dropdown-cover button, .user-front-nav-bar .dropdown-cover button:focus{
		color: #fff !important;
	   }
	   .user-front-nav-bar .dropdown-cover .dropdown-toggle::after {
		background: url(../img/chevron-down-solid-front.svg) no-repeat;
	}
	   
	.user-front-nav-bar .search-inner .input-search::placeholder {
			color: #fff;
		}
		
	   .input-search{
			height: 50px  ;
			border-radius: 10px;
			border: 1px solid #DEDEDE;
			min-width: 360px;
			margin-right: 10px;
			padding-left: 40px !important;
			padding-right: 20px;
			background: url(../img/magnifying-glass-solid.svg) no-repeat !important;
			background-position: 5% !important;
			
			border: 1px solid #DEDEDE !important;
		}
	   .input-search:focus{
			outline: none;
	   }
	   .title-categories{
			font-size: 30px;
			color: #3B3B3C;
			font-family: "NHaasGroteskDSPro-65Md" !important;
			margin-top: 25px;
			margin-bottom: 25px;
			padding-top: 10px;
			padding-bottom: 10px;
	   }
	   .client-name{
			font-size: 18px;
			color: #000000;
			font-family: "NHaasGroteskDSPro-65Md" !important;
	   }
	   .pdf-repeat-box{
		font-size: 20px;
		color: #3B3B3C;
		padding: 13px;
		border-radius: 5px;
		border: 2px solid #fc5400;
		margin-bottom: 10px;
	   }
	   .center-title{
			font-size: 30px;
			color: #3B3B3C;
			font-family: "NHaasGroteskDSPro-65Md" !important;
	   }
	   .pre-btn{
			padding: 16px 20px;
			background-color: #fc5400;
			font-size: 16px;
			color: #FFFFFF;
			font-family: "NHaasGroteskDSPro-65Md" !important;
			border: none;
			border-radius: 10px;
	   }
	   .next-btn{
			padding: 16px 20px;
			background-color: #fc5400;
			font-size: 16px;
			color: #FFFFFF;
			font-family: "NHaasGroteskDSPro-65Md" !important;
			border: none;
			border-radius: 10px;
   		}
	   .next-btn span{
			margin-left: 10px;
	   }
	   .pre-btn span{
		margin-right: 10px;
   }
	   .pdf-main-outer{
			margin-top: 20px;
			
	   }
	   .pdf-btn-outer {
		text-align: center;
		
		padding: 20px 0px;
	}
	   .pdf-btn-outer .react-pdf__Document{
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 20px;
	   }
	   .pdf-btn-outer #zoomInButton{
			font-size: 16px;
			color: #fff !important;
			font-family: "NHaasGroteskDSPro-65Md" !important;
			background: transparent;
			border: none;
	   }
	   .pdf-btn-outer #zoomOutButton{
		font-size: 16px;
		color: #fff !important;
		font-family: "NHaasGroteskDSPro-65Md" !important;
		background: transparent;
		border: none;
   }
   .pages-count{
	font-size: 16px ;
	color: #fff;
   }
   .all-header-in {
    padding-left: 0;
    padding-right: 0;
}
.price-select-one{
	min-width: 350px;
	border: 3px solid #E9E9E9;
	box-shadow: 0px 19px 32px #00000029;
	padding: 45px 20px 20px;
	border-radius: 25px;
	margin-right: 40px;
	text-align: center;
	/*background: #04426b;*/
	background: #fc5400;
}
.price-select-one:hover{
	background-color: #fff;
	border-color: #fc5400;
}
.price-select-one:hover svg path{
	fill: #fc5400;
}
.price-select-one:hover .price-title{
	color: #fc5400;
}
.price-select-three{
	min-width: 350px;
	border: 3px solid #E9E9E9;
	box-shadow: 0px 19px 32px #00000029;
	padding: 45px 45px;
	border-radius: 25px;
	margin-right: 40px;
	text-align: center;
	background: #00a1ff !important;
}

.price-select-one img{
	max-width: 180px;
	margin-bottom: 0;
}
.price-select-three img{
	max-width: 180px;
	margin-bottom: 0;
}
.price-main-outer-link-box{
	color: #fff !important;
}
.price-select-two img{
	max-width: 270px;
	/* min-height: 75px; */
	margin-bottom: 0px;
}
.price-select-two {
    min-width: 350px;
	max-width: 325px;
    border: 3px solid #E9E9E9;
    box-shadow: 0px 19px 32px #00000029;
    padding: 30px 15px 25px;
    border-radius: 25px;
	text-align: center;
	cursor:pointer;
	display: flex;
    align-items: center;
    position: relative;
	margin-right: 40px;
}
.price-select-two:hover{
	border: 3px solid #fc5400;
}
.price-select-three:hover{
	border: 3px solid #fc5400;
	background: #fff;
}
.price-select-three:hover .price-title {
	color: #fc5400;
}
.price-select-three svg path {
	fill: #fff !important;
}
.price-select-three:hover svg path {
	fill: #fc5400 !important;
}
.price-title{
	font-size: 22px;
	color: #003358;
	font-family: 'DrukWideHeavyTrial';
	text-transform: uppercase;
}
.boost-gear-right-icon {
    position: absolute;
    right: 20px;
    transform: rotate(-90deg);
    bottom: 20px;
}
.boost-gear-right-icon img{
	width: 25px;
}
.price-select-one .price-title{
	color: #ffffff;
}
.price-select-three .price-title{
	color: #ffffff;
}
.price-of-new-inner{
	padding-top: 60px;
    padding-bottom: 60px;
    min-height: calc(100vh - 120px);
    display: flex;
    align-items: center;
    justify-content: center;
}
.image-outer-cover{
	min-height: 163px;
}
.deactive-cover{
	margin-top: 33px !important;
	text-align: center;
	max-width: 376px;
	margin: 0 auto;
}
.deactive-title{
	font-size: 15px;
	font-family: "NHaasGroteskDSPro-65Md" !important;
	color: #404040;
	margin-bottom: 8px;
}
.deactive-title p{
	font-size: 15px;
	font-weight: normal;
	color: #404040;
}
.login-section-height {
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 20px;
}
.pdf-repeat-box a,.pdf-repeat-box a:hover{
	color: #3B3B3C;
	font-size: 20px;
	text-decoration: none;
}
.theme-left-sidebar .form-check {
    font-size: 18px;
}
.theme-left-sidebar .form-check .form-check-input {
    font-size: 20px;
}
.dropdown-cover .dropdown-item {
	padding-left: 15px;
	padding-right: 15px;
}
.login-section-height input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active{
	font-size: 16px !important;
    color: #707070 !important;
    font-family: 'NHaasGroteskDSPro-55Rg' !important;
	
}
.disable{
	opacity: .5;
}
.pricetag-title{
	font-size: 30px;
	color: #3B3B3C;
	font-family: "NHaasGroteskDSPro-65Md" !important;
}
.price-btn-cover button{
	background: #3FAC4C;
	color: #fff;
	font-size: 16px !important;
	padding: 15px;
	border-radius: 10px;
	border: none;
	min-width: 120px;
	font-family: "NHaasGroteskDSPro-65Md" !important;
	outline: none;
	box-shadow: none;
}
.price-btn-cover button span{
	margin-right: 5px;
}
.price-tag-modal .modal-dialog{
	max-width: 499px !important; 

}
.price-tag-modal .modal-content{
	border-radius: 0 !important;
}
.price-tag-modal .modal-body{
	padding: 0 13px 0 !important;
}
.preview-pdf-modal.price-tag-modal .modal-body{
	padding: 1rem !important;
}

.bg-black-new {
    background-color: #F1F1F1!important;
}
.price-preview-right-side-cover img {
    max-width: 100%;
}
.price-preview-border{
	border-left: 1px dotted #00000069;
	border-bottom: 1px dotted #00000069;
}
.left-title-of-pdf{
	font-size: 16px;
	color: #00000069;
	font-family: "NHaasGroteskDSPro-65Md" !important;
	padding-left: 0px;
	line-height: normal;
	width: 100%;
    height: 185px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.price-tag-modal.show{
	display: flex !important;
	align-items: center !important;
}
.modal-left-icon{
	text-align: right;
	position: absolute;
	right: 10px;
	top: 171px;
}
.modal-left-icon2{
	top: 357px;
}
.modal-left-icon3 {
    top: 540px;
}
.modal-bottom-icon{
	margin-left: 110px;
	width: auto !important;
	margin-bottom: 20px;
}
.modal-left-icon3{
	margin-left: 162px;
	width: auto;
}
.price-modal-scroll{
	max-height: 630px;
	overflow: auto;
}
/* width */
.price-modal-scroll::-webkit-scrollbar {
	width: 5px;
  }
  
  /* Track */
  .price-modal-scroll::-webkit-scrollbar-track {
	background: #f1f1f1;
  }
  
  /* Handle */
  .price-modal-scroll::-webkit-scrollbar-thumb {
	background: #888;
  }
  
  /* Handle on hover */
  .price-modal-scroll::-webkit-scrollbar-thumb:hover {
	background: #555;
  }
  .theme-left-sidebar .btn-check:checked+.btn, .btn.active, .btn.show, :not(.btn-check)+.btn:active {
    color: var(--bs-btn-active-color);
    background-color: #ED8E2F !important;
    border-color: #ED8E2F !important;
}
.theme-left-sidebar .form-check-input:checked {
    background-color: #ED8E2F;
    border-color: #ED8E2F;
}
.new-red-bg{
	background-color: #FF4A4A ;
	

}
.new-red-bg .btn-close{
	
	opacity: 1;
	font-size: 10px;
}

.btn-blue-outline .theme-left-sidebar .btn-check:checked+.btn, .btn.active, .btn.show,  :not(.btn-check)+.btn:active {
    color: #fff;
    background-color: red !important;
    border-color: red !important;
}
dropdown-cover .theme-left-sidebar .btn-check:checked+.btn, .btn.active, .btn.show,  :not(.btn-check)+.btn:active {
    color: var(--bs-btn-active-color);
    background-color: transparent !important;
    border-color: transparent !important;
}

.pdf-inner-outer a {
    text-decoration: none;
}
.address-wrapper{
	position: absolute;
    background: #fff;
    box-shadow: 0px 1px 6px 3px rgba(0,0,0,.1);
    padding: 20px;
    width: 356px;
	z-index: 50000;
	cursor: pointer;
	height: 200px;
	overflow: scroll;
}
.address-list {
    margin-bottom: 10px;
}
.new-loader {
    color: orange;
    height: 50px !important;
    width: 50px !important;
}
.loader-section{
	display: flex;
	align-items: center;
	justify-content: center;
	height: calc(100vh - 120px);
}
.head-logo-new{
	cursor: pointer;
}
.head-logo-new img{
	max-width: 100%;
}
.pdf-inner-outer a:hover{
	text-decoration: none;
}
.search-main-outer .pdf-repeat-box{
	font-size: 20px;
	color: #1492E6;
	cursor: pointer;
}
.search-main-outer .pdf-repeat-box:hover{
	text-decoration: underline;
}
.search-main-outer .pdf-inner-outer{
	text-align: center;
}

.export-btn-inner{
	padding: 0px 25px !important;
	font-size: 18px;
	color: #3B3B3C;
	font-family: "NHaasGroteskDSPro-65Md" !important;
	background-color: transparent;
	border-radius: 50px;
	border: 1px solid #3B3B3C;
	height: 41px;
}
.btn-and-export-cover .search-inner .input-search{
	min-width: 266px;
	background-position: 95% !important;
	padding-right: 35px !important;
	padding-left: 15px !important;
	height: 41px;
	border-radius: 4px;
}
.export-btn-inner.delete-action{
	color: #fff;
	background: #71CBE4;
	border: none;
	min-width: 130px;
}
.table-inner-view .table td, .table th{
	border-top: none;
}
.header-right .dropdown-item .nav-link{
	font-size: 18px;
    color: #000000;
    font-family: 'NHaasGroteskDSPro-55Rg' !important;
    font-weight: normal;
    padding: 5px 10px;
    line-height: 20px;
	font-weight: 500;
}
	.hstack {
		display: flex;
		flex-direction: row;
		align-items: center;
		align-self: stretch;
	}
	
	.gap-4 {
		gap: 1.5rem;
	}
	
	.ratio {
		position: relative;
		width: 100%;
	}
	
	.ratio-1x1 {
		--bs-aspect-ratio: 100%;
	}

	.ratio > * {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
		
	.ratio::before {
		display: block;
		padding-top: var(--bs-aspect-ratio);
		content: "";
	}
	.theme-left-sidebar .form-check-label,.theme-checkbox-label {
		margin-bottom: 0;
		font-size: 18px;
		color: #707070;
		font-family: "NHaasGroteskDSPro-65Md" !important;
		font-family: "NHaasGroteskDSPro-65Md";
		
	}

	.theme-left-sidebar .check-only-box .form-check-label {
		pointer-events: none;
	}



	/* The container */
	.theme-left-sidebar .form-check {
		display: flex;
		align-items: center;
		position: relative;
		padding-left: 35px;
		margin-bottom: 7px;
		cursor: auto;
		font-size: 22px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	  }
	  
	  /* Hide the browser's default checkbox */
	  .theme-left-sidebar .form-check .form-check-input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	  }
	  .custom-toggle input{
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	  }
	  .custom-toggle{
		border: 2px solid #00416B;
	  }
	  
	  .custom-toggle.active {
		background: #00416B !important;
	  }
	  .custom-toggle.new-radio.active {
		background: #ED8E2F !important;

	  }
	  
	  .new-radio{
		margin-right: 10px;
	  }
	  
	  /* Create a custom checkbox */
	  .theme-left-sidebar .form-check-label::before {
		content: "";
		position: absolute;
		top: 0px;
		left: 0;
		height: 20px;
		width: 20px;
		background-color: #eee;
		border-radius: 4px;
	  }
	  
	  .theme-left-sidebar .check-only-box .form-check-label::before {
		pointer-events: auto;
		cursor: pointer;	
	  }
	  .theme-left-sidebar .form-check-label.last-ride::before {
		
		border-radius: 0px;
		
	  }
	  
	  /* On mouse-over, add a grey background color */
	  .theme-left-sidebar .form-check:hover .form-check-input ~ .form-check-label::before {
		background-color: #ccc;
	  }
	  
	  /* When the checkbox is checked, add a blue background */
	  .theme-left-sidebar .form-check .form-check-input:checked ~ .form-check-label::before {
		background-color: #ED8E2F;
	  }
	  
	  /* Create the form-check-label/indicator (hidden when not checked) */
	  .theme-left-sidebar .form-check-label:after {
		content: "";
		position: absolute;
		display: none;
	  }
	  
	  .theme-left-sidebar .check-only-box .form-check-label:after {
		pointer-events: auto;
		cursor: pointer;
	  }
	  /* Show the form-check-label when checked */
	  .theme-left-sidebar .form-check .form-check-input:checked ~ .form-check-label:after {
		display: block;
	  }
	  
	  /* Style the form-check-label/indicator */
	  .theme-left-sidebar .form-check .form-check-label:after {
		left: 7px;
		top: 4px;
		width: 6px;
		height: 11px;
		border: solid white;
		border-width: 0 3px 3px 0;
		transform: rotate(45deg);
	}
	.custom-new-button  {
		width: 26px;
		height: 26px;
		border: 2px solid #fff;
		right: -17px;
		top: -17px;
	} 
	.form-control-file{
		height: 200px !important;
	}
	.upload-btn-cover .upload-btn{
		color: #fc5400 !important;
		border: 1px solid #fc5400 !important;
		margin-right: 15px !important;
	}
	.updating-sheet-modal .modal-dialog{
		max-width: 280px !important;
		margin: 0 auto !important;
		
	}
	.updating-sheet-modal .modal-body{
		max-width: 280px;
		margin:  0 auto;
		padding: 15px !important;
	}
	.updating-sheet-modal .modal-content{
		padding: 22px;
	}
	.uploading-text{
		color: #fc5400;
		font-size: 20px;
		font-family: "NHaasGroteskDSPro-65Md" !important;
		text-align: center;
	}
	.updating-sheet-modal .modal-body{
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
	.uploading-text-sub{
		font-size: 15px;
		color: #000000;
		font-weight: normal;
		text-align: center;
		margin-top: 15px;
		line-height: normal;
	}

	.price-preview-border {
		padding-top: 15px;
		padding-bottom: 15px;
	}
	.price-preview-right-side-cover {
		background-color: #FFF!important;
		height: 100%;
		width: 100%;
		min-height: 183px;
	}
	
	.check-image-cover{
		top: 0;
    left: 0;
    z-index: 2;
	}



	
		.price-tag-modal.print-modal .modal-dialog{
			/* max-width: 499px !important; */
			max-width: 800px !important;
		}
		.print-modal .price-modal-scroll{
			max-height: 1100px;
			overflow: auto;
		}
		.print-modal .price-modal-scroll.zero-height{
			max-height: 0px;
			overflow: auto;
		}
		.print-modal  .col-md-3 {
			flex: 0 0 17%;
			max-width: 17%;
			padding-right: 0;
		}
		.print-modal  .col-md-9 {
			flex: 0 0 83%;
			max-width: 83%;
			
		}
		.print-modal .col-md-6.price-preview-border{
			padding: 0px;

		}
		.print-modal .price-preview-right-side-cover{
			min-height: 330px;
			background-color: #fff !important;
		}
		.print-modal .price-preview-right-side-cover img{
			height: 330px;
			width:330x;
			max-width: auto;
		}
		.print-modal .left-title-of-pdf{
			height: 332px;
		}

		.print-modal .modal-left-icon{
			top: 320px;
		}
		.print-modal .modal-left-icon2{
			top: 650px;
		}

		.print-modal .modal-left-icon3{
			top: 982px;
		}

		.print-modal .modal-bottom-icon{
			margin-left: 130px;
		}

		.print-modal .modal-bottom-icon.modal-left-icon3{
			margin-left: 320px;
		}
	
/* end */

.drop-one{

    padding-top: 0;

    padding-bottom: 0 ;

    min-height: 3px !important;

    max-height: 35px !important;

    background-color: #e3e6de;

}

.push-btn{

    background-color: #d9e8fb !important;

    color: #212529 !important;

    border-radius: 5px !important;

    border: none !important;

}

span.showText {

    min-width: 65px;

}

.action-icons i{

    min-width: 5px;

   

}

.action-icons{

    cursor: pointer;

}

.btn-secondary.Edit-btn-version-secondary:first-child:active{
	background: #003960 !important;
	border-color: #003960 !important;
}
.btn-primary.Edit-btn-version-secondary:active{
	background: #fc5400 !important;
	border-color: #fc5400 !important;
}







.mw-150{
	max-width: 150px;
}

.pre-btn svg,.next-btn svg{
	width: 10px;
    height: 20px;
    margin-top: -4px;

}

.price-boxs{
	width: 33.33%;
	padding: 0px 20px;
	max-width: 364px;
}
.price-boxs .price-select-two,
.price-boxs .price-select-one,
.price-boxs .price-select-three{
	min-width: 100%;
	max-width: 100%;
	height: 100%;
	max-height: 315px;
}
.price-icon-cover svg{
	width: 90%;
	max-height: 100%;
}
.price-boxs a {
    text-decoration: none !important;
}
/* responsive css */
@media (max-width: 1349px){
	.rightDatePicker, .leftSearch {     
		flex: 0 0 100% !important;
		max-width: 100% !important;
	}
	.rightDatePicker.pl-xl-0{
		padding-left: 15px !important;
	}
}

@media (min-width: 1200px){
	.pricetag-title{
		font-size: 22px;
	}
	.align{
		align-items: center;
	}
	
}
@media (max-width: 1199px){
	.search-inner .input-search{
		min-width: inherit;
	}
	.search-main-outer .pdf-repeat-box{
		text-align: left;
		word-break: break-all;
	}
	.price-boxs {
		padding: 0px 10px;
	}
	.price-select-one{
		padding: 45px 30px;
	}
	.price-select-three{
		padding: 45px 30px;
	}
	.price-select-two {
	   
	        min-height: 300px;
    margin-right: 0px;
    margin-bottom: 20px;
	    
	}
	.price-select-two img{
		max-width: 100%;
	}
	.price-icon-cover svg {
		width: 70%;
	}
	
	
}
@media (max-width: 991px){
	.search-inner.d-flex,.search-inner .d-flex.gap-4,.search-inner .wrapper {
		width: 100%;
	}
	
	.container {
	    max-width: 100% !important;
	}
	.price-select-one img{
		width: 100%;
	}
	.price-title{
		font-size: 16px;
	}
	.file-list-left-border{
		border: none !important;
	}
}
@media (max-width: 767px){
	.pricetag-title{
		font-size: 18px;
	}
	.align{
		align-items: center;
	}
	.white-outer-box{
		padding: 30px 20px;
	}
	.pre-btn,.next-btn {
		font-size: 0;
	}
	.pre-btn svg,.next-btn svg{

		margin-top: 0px;
	
	}
	.price-main-outer{
		flex-wrap: wrap!important;
	}
	.price-select-one{
		margin-right: 0;
		margin-bottom: 10px;
		padding: 45px 10px;
		width: 100%;
		min-width: 100%;
	}

	.price-select-three{
		margin-right: 0;
		margin-bottom: 10px;
		padding: 45px 10px;
		width: 100%;
		min-width: 100%;
	}

	.price-of-new-inner{
		height: inherit;
	}

	.price-select-two{
		width: 100%;
		min-height: 345px;
		max-width: 100%;
		margin-right: 0px;
		margin-bottom: 20px;
		min-width: 100%;
	}
	.price-main-outer-link-box{
		width: 100%;
		
	}
	.price-tag-modal .modal-dialog{
		width: 96%;
	}
	.preview-pdf-modal.price-tag-modal .modal-body{
		    min-width: 490px;
	}
	.cross-calendar {
		right: 48px !important;
	}
	.price-boxs {
		width: 100%;
		padding: 15px 20px;
	}
	.price-icon-cover svg {
		width: 160px;
	}
	.price-select-two img {
		max-width: 270px;
	}
}
@media (min-height: 375px) and (max-height: 414px){
	.price-modal-scroll{
		    max-height: 340px
	}
}
@media (max-width: 575px){
	.head-logo-new {
		max-width: 220px;
	}
	.user-front-nav-bar{
		padding: 16px !important;
	}
	.price-select-two img{
        max-width: 100%;
    }
    .cross-calendar {
		right: 65px !important;
	}
	
}
.price-icon-cover {
	height: 180px;
    display: flex;
	justify-content: center;
    align-items: center;
}

/* CSS to style the default calendar icon */
.react-datepicker-wrapper .react-datepicker__input-container input{
	background: url('../../assets/img/calendar.svg');
	background-repeat: no-repeat;
    background-size: 16px;
    background-position: 92% center;
    background-color: #fff;
}

.cross-calendar {
	border: none;
    position: absolute;
    right: 55px;
    z-index: 9;
    top: 10px;
    background: none;
    color: #555;
}

.price-select-two{
	flex-flow: wrap;
	justify-content: center;
}
.price-select-two-img{
	flex-flow: wrap;
	width: 100%;
}
.price-select-two-img img{
	max-width: 150px;
    margin-bottom: 10px;

}

.new-panel-work a{
	margin-right: 25px;
	color: #000;
	font-family: "NHaasGroteskDSPro-65Md" !important;
} 
.sign-in-link{
	position: absolute;
	right: 20px;
	bottom: 10px;
}
.sign-in-link a {
	color: #000;
    font-family: "NHaasGroteskDSPro-65Md" !important;
}
.price-main-box{
	width: 100%;
    max-width: 1300px;
}
.main-cover {
	position: relative;
	padding-bottom: 50px;
	min-height: calc(100vh - 120px);
	display: inline-block;
    width: 100%;
    vertical-align: top;
}
.table thead th{
	font-family: "NHaasGroteskDSPro-65Md" !important;
	font-weight: normal;
}
@media (max-width: 991px){
	.price-select-two-img img {
		max-width: 120px;
	}
	.price-boxs .price-select-two, .price-boxs .price-select-one, .price-boxs .price-select-three {
		max-height: 310px;
	}
}

.mListIcon {
	cursor: pointer;
	max-width: 100px;
    max-height: 100px;
}

.mListIconViewModal {
	cursor: pointer;
	width: 100px;
}

.alertArea textarea {
	height: 100px !important;
	resize: none;
}

.view-alert-modal .modal-dialog {
	max-width: 800px !important;
} 

.viewAlertTd {
	width: 150px;;
}

.alert-image-box img {
	max-width: 100%;
	max-height: 100%;
}

.pdf-modal-dragbox {
	cursor: pointer !important;
}

.form-control-file {
	cursor: pointer !important;
}

.alert-image-box {
	cursor: pointer !important;
}

.send-new-alert-button {
	outline: none !important;
    box-shadow: none !important;
}

.viewMessage {
	word-break: break-word !important;
}

.digital-box{
	background-color: #e3e3e3 !important;
	border-color: #e3e3e3 !important;
}
.digital-box:hover{
	border-color: #E76920 !important;
}
.digital-box .price-title, .digital-box:hover .price-title{
	color: #000;
	
}
.digital-box-link{
	text-decoration: none !important;
}
.digital-icon-img{
	max-height: 160px;
    margin-bottom: 20px !important;
}

.mFileCount {
	font-family: "NHaasGroteskDSPro-65Md" !important;
}

.fliyer-list-title { 
	font-size: 20px;
    color: #3B3B3C;
}

.fliyer-list-title:hover { 
	font-size: 20px;
    color: #3B3B3C;
}

.mErrorMessage {
	color: red !important;
}

.flyer-search {
	min-width: inherit !important;
	background-position: 15px center !important;
}

.categoryNameOnEdit {
	font-family: "NHaasGroteskDSPro-65Md" !important;
    margin-left: 2%;
    color: #707070;
}
.files-listing-title{
	font-size: 30px;
    font-family: "NHaasGroteskDSPro-65Md" !important;
}
.file-list-left-box{
	height: 502px;
    border: 1px solid #a8abad !important;
    border-radius: 20px !important;
    overflow: hidden;
	justify-content: center;
    font-size: 20px;
	align-items: center;
}
.file-list-left-box img{
	/* width: 100%; */
	/* height: 100%; */
	/* object-fit: cover; */
	max-width: 100%;
	max-height: 100%;
}

.file-list-left-border{
	border-right: 3px solid #000;
	padding-right: 30px !important;

}
.file-list-right{
	padding-left: 30px !important;
}

.no-records-message {
	height: 100%;
    font-size: 25px;
}
.digital-flyer-table th{
	font-family: "NHaasGroteskDSPro-65Md" !important;
	font-size: 20px;
	font-weight: normal;
}

.w-200 {
    width: 200px;
}
.min-h-195{
	min-height: 176px;
}